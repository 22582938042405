import React from 'react';

import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Layout from './components/Layout';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { SnackbarProvider } from './contexts/SnackbarContext';
import Dashboard from './pages/Dashboard';
import HouseholdSettings from './pages/HouseholdSettings';
import Login from './pages/Login';
import Profile from './pages/Profile';
import ShoppingList from './pages/ShoppingList';
import store from './store';
import theme from './theme';
import './config/firebase';

const PrivateRoute = ({ children }) => {
  const { user, loading } = useAuth();
  console.log('PrivateRoute:', { user: user?.email, loading });

  if (loading) {
    return (
      <div className="loading">
        <div>Loading authentication...</div>
      </div>
    );
  }
  return user ? children : <Navigate to="/login" replace />;
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

const App = () => {
  console.log('App rendering');
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider>
            <AuthProvider>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <Layout />
                    </PrivateRoute>
                  }
                >
                  <Route index element={<Dashboard />} />
                  <Route path="lists/:listId" element={<ShoppingList />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="settings" element={<HouseholdSettings />} />
                </Route>
              </Routes>
            </AuthProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
