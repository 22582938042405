import React, { createContext, useContext, useState, useEffect } from 'react';

import { signInWithPopup, signOut, onAuthStateChanged } from 'firebase/auth';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from './SnackbarContext';
import { auth, googleProvider } from '../config/firebase';

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Setting up auth state listener');
    const unsubscribe = onAuthStateChanged(auth, user => {
      console.log('Auth state changed:', user ? 'User logged in' : 'No user');
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const signInWithGoogle = async () => {
    try {
      console.log('Attempting Google sign in');
      const result = await signInWithPopup(auth, googleProvider);
      console.log('Google sign in successful:', result.user.email);
      setUser(result.user);
      showSnackbar('Successfully signed in!', 'success');
      navigate('/');
    } catch (error) {
      console.error('Error signing in with Google:', error);
      showSnackbar(error.message, 'error');
    }
  };

  const logout = async () => {
    try {
      console.log('Attempting to sign out');
      await signOut(auth);
      setUser(null);
      showSnackbar('Successfully signed out!', 'success');
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
      showSnackbar(error.message, 'error');
    }
  };

  const value = {
    user,
    loading,
    signInWithGoogle,
    logout,
  };

  console.log('AuthProvider state:', { loading, user: user?.email });

  if (loading) {
    return (
      <div className="loading">
        <div>Loading authentication...</div>
      </div>
    );
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
