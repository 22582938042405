import React, { createContext, useContext, useState, useEffect } from 'react';

import {
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';
import PropTypes from 'prop-types';

import { auth, googleProvider } from '../config/firebase';
import { analyticsService } from '../services/analyticsService';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const login = async (email, password) => {
    try {
      setError('');
      const result = await signInWithEmailAndPassword(auth, email, password);
      analyticsService.trackEvent('login_success', { method: 'email' });
      return result;
    } catch (err) {
      setError(err.message);
      analyticsService.trackEvent('login_error', {
        method: 'email',
        error: err.message,
      });
      throw err;
    }
  };

  const signInWithGoogle = async () => {
    try {
      setError('');
      const result = await signInWithPopup(auth, googleProvider);
      analyticsService.trackEvent('login_success', { method: 'google' });
      return result;
    } catch (err) {
      setError(err.message);
      analyticsService.trackEvent('login_error', {
        method: 'google',
        error: err.message,
      });
      throw err;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      analyticsService.trackEvent('logout_success');
    } catch (err) {
      setError(err.message);
      analyticsService.trackEvent('logout_error', { error: err.message });
      throw err;
    }
  };

  const value = {
    user,
    login,
    signInWithGoogle,
    logout,
    error,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
